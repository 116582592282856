import { request } from "@/request/http";
export default {
  getVirtualDataList: (data) => {
    return request("GET", `/index.php/course/getVirtualDataList`, data);
  },

  unLockTotalExp: (data) => {
    return request("GET", `/index.php/course/unLockTotalExp`, data);
  },

  getGroupList: (data) => {
    return request("GET", `/index.php/group/getGroupList`, data);
  },

  updateCourseGroup: (data) => {
    return request("GET", `/index.php/course/updateCourseGroup`, data);
  },

  getCourseScoreData: (data) => {
    return request("GET", `/index.php/course/getCourseScoreData`, data);
  },

  updateCourseWeight: (data) => {
    return request("GET", `/index.php/course/updateCourseWeight`, data);
  },

  updateCourseProject: (data) => {
    return request("GET", `/index.php/course/updateCourseProject`, data);
  },

  getProjectList: (data) => {
    return request("GET", `/index.php/course/getProjectList`, data);
  },

  getCourseProjectList: (data) => {
    return request("GET", `/index.php/course/getCourseProjectList`, data);
  },

  updateCourseSet: (data) => {
    return request("POST", `/index.php/course/updateCourseSet`, data);
  },

  getTogetherListEsec: (data) => {
    return request("GET", `/index.php/course/getTogetherListEsec`, data);
  },

  addTogether: (data) => {
    return request("GET", `/index.php/project/addTogether`, data);
  },

  searchTogether: (data) => {
    return request("GET", `/index.php/course/searchTogether`, data);
  },

  getCourseInfo: (data) => {
    return request("GET", `/index.php/course/getCourseInfo`, data);
  },

  hideCourse: (data) => {
    return request("GET", `/index.php/course/hideCourse`, data);
  },

  deleteCourse: (data) => {
    return request("GET", `/index.php/course/deleteCourse`, data);
  },

  recoverCourse: (data) => {
    return request("GET", `/index.php/course/recoverCourse`, data);
  },

  createCourse: (data) => {
    return request("GET", `/index.php/course/createCourse`, data);
  },

  getCourseList: (data) => {
    return request("GET", `/index.php/course/getCourseList`, data);
  },

  updateCourseSortNo: (data) => {
    return request("POST", `/index.php/course/updateCourseSortNo`, data);
  },

  getCourseStudentGroupList: (data) => {
    return request("POST", `/index.php/course/getCourseStudentGroupList`, data);
  },

  getOpenCourseInfo: (data) => {
    return request("POST",`/index.php/course/getOpenCourseInfo`,data)
  },

  updateOpenCourseInfo: (data) => {
    return request("POST",`/index.php/course/updateOpenCourseInfo`,data)
  },

  transferCourse:(data) => {
    return request("POST",`/index.php/course/transferCourse`,data)
  }
  
};