<template>
  <!-- 素材列表页 -->
  <div class="course">
    <!-- 左侧模块 -->
    <div class="lock" @click="lockSort">
      <img class="lockImg" src="http://moolsvideo.oss-cn-beijing.aliyuncs.com/baiduOCR/2022-11-02-14-11-21_3473669.png"
        alt="" v-show="sortAbled" />
      <img class="lockImg" src="http://moolsvideo.oss-cn-beijing.aliyuncs.com/baiduOCR/2022-11-02-14-12-25_9033983.png"
        alt="" v-show="!sortAbled" />
      <!-- <i class="el-icon-lock" v-show="!sortAbled"></i> 
      <i class="el-icon-unlock"  v-show ="sortAbled"></i> -->
    </div>
    <div class="left-module">
      <!-- 左侧模块 -->
      <div class="addnewCourse">
        <!-- 搜索框 -->
        <div class="div-height">
          <div class="search-div30">
            <el-button @click="handleOpen('create', '添加课程')" class="left themeButton addnewCourseButton">新建课程</el-button>
          </div>
          <!-- <div @click="lockSort">点击</div> -->
          <div class="search-div70">
            <el-select v-model="class_type" placeholder="请选择" class="input-div search-el-select" @change="getCourseList">
              <!-- 查与我有关系的所有项目 -->
              <el-option v-for="item in class_typeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
        </div>
        <!-- 项目列表 -->
        <div>
          <div class="projectListBox">
            <table class="sortableTable" ref="table" v-show="sortAbled">
              <tr :id="item.id" v-for="(item, index) in courseList" :key="index">
                <!-- 排序 -->
                <!-- <td class="width20">
                                <el-input
                                    v-model="item.sort_no"
                                    class="width100"
                                    @blur="sortNoUpdate(item)"
                                ></el-input>
                </td>-->
                <!-- 项目名称 -->
                <td class="td-project" @click="getcurrentActive(item.id, item.name)">{{ item.name }}</td>
                <!-- 操作 -->
                <td class="td-handle" v-show="item.is_create">
                  <el-dropdown>
                    <span class="el-dropdown-link">
                      <i class="el-icon-more"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="handleShare( item.id, item.name)">
                      <div class="codeItem">共享</div>
                    </el-dropdown-item>
                      <el-dropdown-item @click.native="handleOpen('hide', '隐藏项目', item.id, item.name)">
                        <div class="codeItem">隐藏</div>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </td>
              </tr>
            </table>
            <table ref="table" v-show="!sortAbled">
              <tr :id="item.id" v-for="(item, index) in courseList2" :key="index">
                <!-- 排序 -->
                <!-- <td class="width20">
                                <el-input
                                    v-model="item.sort_no"
                                    class="width100"
                                    @blur="sortNoUpdate(item)"
                                ></el-input>
                </td>-->
                <!-- 项目名称 -->
                <td class="td-project" @click="getcurrentActive(item.id, item.name)">{{ item.name }}</td>
                <!-- 操作 -->
                <td class="td-handle" v-show="item.is_create">
                  <el-dropdown>
                    <span class="el-dropdown-link">
                      <i class="el-icon-more"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="handleShare( item.id, item.name)">
                      <div class="codeItem">共享</div>
                    </el-dropdown-item>
                      <el-dropdown-item @click.native="handleOpen('hide', '隐藏项目', item.id, item.name)">
                        <div class="codeItem">隐藏</div>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <br />
      </div>
      <!-- 已删除项目 -->
      <div class="historyCourse">
        <div class="delete-title">
          <div @click="historyCourseShow()">
            历史课程({{ historyNum }})
            <i class="el-icon-arrow-down" v-if="!deleteCourseShowSign"></i>
            <i class="el-icon-arrow-up" v-if="deleteCourseShowSign"></i>
          </div>
        </div>
        <div class="historyDelProject">
          <table v-if="deleteCourseShowSign" class="brown">
            <!-- <tr  :id="'del_'+item.id" -->
            <tr :id="item.id" v-for="(item, index) in historyCourseList" :key="index">

              <!-- 项目名称 -->
              <!-- <td class="delete-td-project" @click="getCourseInfo(item.id,item.name,'delete',item.isShow)">{{item.name}}</td> -->
              <td class="delete-td-project" @click="hideCourse(item.id, item.name, 'delete', item.isShow)">{{ item.name }}</td>
              <!-- 操作 -->
              <td class="td-handle">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <i class="el-icon-more"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="handleOpen('recover', '恢复项目', item.id, item.name)">
                      <div class="codeItem">恢复</div>
                    </el-dropdown-item>
                    <el-dropdown-item @click.native="handleOpen('delete', '删除项目', item.id, item.name)">
                      <div class="codeItem">删除</div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="div">
      <!-- 右侧模块 -->
      <div>
        <!-- 标签页 -->
        <el-tabs v-model="activeName" @tab-click="tabHandleClick">
          <!-- 设置 -->
          <el-tab-pane label="设 置" name="first" class="set">
            <div class="setDetail" v-if="courseList.length > 0">
              <div v-if="rightContentShow">
                <div class="fixButton">
                  <el-button :disabled="!is_create" class="themeButton save-set-button"
                    @click="statCourseGroup()">统计</el-button>
                  <el-button :disabled="!is_create" class="themeButton save-set-button"
                    @click="updateCourseSet()">保存</el-button>
                </div>
                <div class="clearFix" v-if="courseClassList.length > 0">
                  <div class="classBindListTitle">绑定班级：</div>
                  <div class="classBindListBox">
                    <div v-for="(item) in courseClassList">
                      <div v-if="item.is_jump" @click="jumpToGroup(item.id)" class="classBindList">{{ item.name }}</div>
                      <div v-else class="classBindListO">{{ item.name }} </div>
                    </div>
                  </div>
                </div>
                <div class="clearFix" v-if="courseProjectList.length > 0">
                  <div class="classBindListTitle">绑定项目：</div>
                  <!-- <div class="classBindListBox"><div @click="jumpToProject(item.id)" :class="classBindList" v-for="(item) in courseProjectList">{{item.name}}</div></div> -->
                  <div class="classBindListBox">
                    <div v-for="(item) in courseProjectList">
                      <div v-if="item.is_jump" @click="jumpToProject(item.id)" class="classBindList">{{ item.name }}</div>
                      <div v-else class="classBindListO">{{ item.name }} </div>
                    </div>
                  </div>
                </div>
                <!-- 课程名称 -->
                <div>
                  <span>课程名称：</span>
                  <el-input :disabled="!is_create" v-model="courseInfo.name" placeholder="请输入课程名称"
                    class="setInput input-div"></el-input>
                  课程创建者：{{ courseInfo.createName }}
                </div>
                <!-- 周选数量 -->
                <div>
                  <span>周选数量：</span>
                  <el-input :disabled="!is_create" v-model="courseInfo.weekly_project" placeholder="请输入周选数量 "
                    class="setInput input-div" type="number"></el-input>
                </div>
                <!-- 最小学时： -->
                <div>
                  <span>最小学时：</span>
                  <el-input :disabled="!is_create" v-model="courseInfo.minhours" placeholder="请输入学时"
                    class="setInput input-div" type="number"></el-input>
                </div>
                <!-- 最大学时： -->
                <div>
                  <span>最大学时：</span>
                  <el-input :disabled="!is_create" v-model="courseInfo.maxhours" placeholder="请输入学时"
                    class="setInput input-div" type="number"></el-input>
                </div>
                <!-- 课程负责人：下拉列表 -->
                <div v-show="is_create">
                  <span>课程负责人：</span>
                  <el-select v-model="create_uid" placeholder="请选择课程负责人" clearable disabled>
                    <el-option v-for="(item, index) in togetherList_copy" :key="index" :label="item.name"
                      :value="item.id"></el-option>
                  </el-select>
                  <el-button class="themecancelButton margin-left10"
                    @click="setHandleOpen('add_together', '添加同事')">添加同事</el-button>
                  <el-button class="themecancelButton margin-left10"
                    @click="moveHandleOpen('move_course', '移交课程')">
                    移交课程
                  </el-button>
                </div>
                <!-- 课程团队：下拉列表（可多选） -->
                <div>
                  <span>课程团队：</span>
                  <el-select :disabled="!is_create" class="project_team" id="teamSelect" multiple v-model="course_team"
                    placeholder="请选择课程团队">
                    <el-option v-for="(item, index) in togetherList" :key="index" :label="item.name"
                      :value="item.id"></el-option>
                  </el-select>
                </div>
                <!-- 预约策略 -->
                <div>
                  <span>预约策略：</span>
                  <el-select :disabled="!is_create" v-model="courseInfo.strategy" placeholder="请选择" clearable>
                    <el-option label="无" :value="0"></el-option>
                    <el-option label="学时控制" :value="1"></el-option>
                    <el-option label="多选控制" :value="2"></el-option>
                    <el-option label="学时多选控制" :value="3"></el-option>
                  </el-select>
                </div>
                <!-- 关联证书 -->
                <div>
                  <span>关联证书：</span>
                  <el-select :disabled="!is_create" v-model="courseInfo.cert_id" placeholder="请选择" clearable>
                    <el-option label="无" :value="0"></el-option>
                    <el-option v-for="(item, index) in certList" :key="index" :label="item.name"
                      :value="item.id"></el-option>
                  </el-select>
                </div>
                <!-- 证书统计： -->
                <!-- <div>
                  <span>证书统计：</span>
                  {{getCert}}/{{certAll}}（已关联/全部）
                </div> -->
                <!-- 课程描述 -->
                <div>
                  <span>课程描述：</span>
                  <el-input :disabled="!is_create" type="textarea" :rows="2" placeholder="请输入课程描述"
                    v-model="courseInfo.des" class="setInput input-div project-desc"></el-input>
                </div>
                <!-- 封面图 -->
                <div>
                  <span>封面图：</span>
                  <el-upload :disabled="!is_create" class="avatar-uploader el-upload-padding" :action="uploadFilePath"
                    :show-file-list="false" :data="{ id: course_id }" :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload">
                    <img v-if="courseInfo.pic" :src="courseInfo.pic" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </div>
              <div v-else>
                <div class="recover-button">
                  <h3>{{ courseName }}</h3>
                  <br />
                  <el-button :disabled="!is_create" class="themeButton"
                    @click="handleOpen('recover', '恢复课程')">恢复</el-button>
                </div>
              </div>
            </div>
            <div class="noCourse" v-else>
              <div class="text">暂无课程，</div>
              <div class="text clickcreateCourse" @click="handleOpen('create', '添加课程')">点击新建课程</div>
            </div>
          </el-tab-pane>
          <!-- 项 目 -->
          <el-tab-pane label="项 目" name="second" class="projectPanel">
            <div class="projectList setDetail" v-if="rightContentShow">
              <div class="clearFix">
                <div class="left">
                  <div class="totalHourAndPeriod">
                    <span class="totalHour">总学时：{{ projectTotalHours }}</span>
                    <span class="totalPeriod">总权重：{{ projectTotalWeight.toFixed(2) }} %</span>
                  </div>
                </div>
                <div class="right">
                  <el-button :disabled="!is_create" class="themeButton bindproject"
                    @click="projectDialogOpen()">绑定项目</el-button>
                </div>
              </div>
              <!-- <table> stripe 隔行换色显示属性 -->
              <el-table :data="projectHasList" :row-class-name="projectTableRowClassName" style="width: 100%">
                <!-- 序号（排序使用） -->
                <el-table-column label="序号" width="80" type="index">
                  <template slot-scope="scope">
                    <span>
                      <el-input v-model="scope.row.project_grp" @blur="sortNoUpdate(scope.row)"
                        style="width:50px"></el-input>
                    </span>
                  </template>
                </el-table-column>
                <!-- 项目名称 -->
                <el-table-column prop="name" label="项目名称">
                  <template slot-scope="scope">
                    <span @click="jumpToProject(scope.row.project_id)" class="cell cursor">{{ scope.row.name }}</span>
                  </template>
                </el-table-column>
                <!-- 学时 -->
                <el-table-column prop="hours" label="学时"></el-table-column>
                <!-- 虚拟实验名称 -->
                <el-table-column label="虚拟实验名称">
                  <template slot-scope="scope">
                    <span class="cell">{{ scope.row.expname }}</span>
                  </template>
                </el-table-column>
                <!-- 权重 -->
                <el-table-column label="权重 %" width="180">
                  <template slot-scope="scope">
                    <span>
                      <el-input :disabled="!is_create" class="weightInput" v-model="scope.row.proportion"
                        @blur="weightUpdate(scope.row)"></el-input>%
                    </span>
                  </template>
                </el-table-column>
              </el-table>
              <!-- 保存按钮 -->
              <!-- <div>
                            <span>
                                <el-button type="primary" class="width100" @click="saveCourseProject()">保存</el-button>
                            </span>
              </div>-->
            </div>
            <div v-else>
              <div class="recover-button">
                <h3>{{ courseName }}</h3>
                <br />
                <el-button :disabled="!is_create" class="themeButton"
                  @click="handleOpen('recover', '恢复项目')">恢复</el-button>
              </div>
            </div>
          </el-tab-pane>
          <!-- 成 绩 -->
          <!-- <el-tab-pane label="成 绩" name="third" class="mark">
            <div v-if="rightContentShow" class="setDetail">
              <div class="report_top_div">
                <div class="report_second_div">
                  <el-select
                    v-model="scoreGroupId"
                    placeholder="请选择"
                    @change="scoreSelectChange()"
                    clearable
                  >
                    <el-option
                      v-for="item in scoreGroupList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <el-table :data="scoreTableData" stripe max-height="680" style="width: 100%">
                <el-table-column
                  fixed="left"
                  type="index"
                  label="序号"
                  :index="indexMethod"
                  width="80"
                  sortable
                ></el-table-column>
                <el-table-column fixed="left" prop="personal_id" width="120" label="学号"></el-table-column>
                <el-table-column fixed="left" prop="name"  width="120" label="姓名"></el-table-column>
                <el-table-column
                  width="180"
                  v-for="item in scoreTableTitleList"
                  :prop="item.id"
                  :key="item.id">
                  <template slot="header" slot-scope="scope">
                    <div>{{item.name.substring(0,item.name.indexOf("*"))}}</div>
                    <div>{{"权重: " + item.name.substring(item.name.indexOf("*")+1).substring(0,item.name.substring(item.name.indexOf("*")+1).indexOf("("))+ "%"+"学时:"+item.name.substring(item.name.indexOf("*")+1).substring(item.name.substring(item.name.indexOf("*")+1).indexOf("("))}}</div>
                  </template>
                </el-table-column>
                <el-table-column fixed="right" prop="final_score" label="总分"></el-table-column>.
                <el-table-column fixed="right" prop="sumhours" label="学时"></el-table-column>
                <el-table-column fixed="right" label="通过">
                  <template slot-scope="scope">
                    <div class="throwScore" v-html="scope.row.sign"></div>
                  </template>
                </el-table-column>
              </el-table>

              <br />
              <div class="page-div padding-top border0">
                <el-pagination
                  @current-change="handleScorePageChange"
                  background
                  layout="prev, pager, next"
                  :current-page.sync="scorePage"
                  :total="scoreDataTotal"
                  :page-size="scorePageSize"
                  v-if="scoreDataTotal != 0"
                ></el-pagination>
              </div>
              <br />
            </div>
            <div v-else>
              <div class="recover-button">
                <h3>{{courseName}}</h3>
                <br />
                <el-button
                  class="themeButton"
                  :disabled="!is_create"
                  @click="handleOpen('recover', '恢复项目')"
                >恢复</el-button>
              </div>
            </div>
          </el-tab-pane> -->
          <!-- 虚 拟 -->
          <el-tab-pane label="虚 拟 实 验 批 量 解 锁" name="fourth" class="exp">
            <div v-if="isShow">
              <div class="expSearch">
                <el-input clearable placeholder="请输入项目名称" v-model="search" @clear="getVirtualDataList">
                  <el-button slot="append" class="el-icon-search" @click="getVirtualDataList"></el-button>
                </el-input>
              </div>
              <div class="report_top_div">
                <div class="allUnlock">
                  <el-select v-model="group_id" placeholder="请选择班级" @change="getVirtualDataList" clearable>
                    <el-option v-for="item in groupList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </div>
                <el-button class="themeButton bindButton" @click="showallunlockDialogVisible">全部解锁</el-button>
              </div>
              <div class="expprojectList">
                <el-table :data="expprojectList" style="width: 100%">
                  <el-table-column type="index" label="序号"></el-table-column>
                  <el-table-column prop="project_name" label="项目名称"></el-table-column>
                  <el-table-column prop="exp_name" label="虚拟实验名称"></el-table-column>
                  <el-table-column label="解锁情况(已解锁/全部)">
                    <template slot-scope="scope">
                      <div v-if="scope.row.is_school == 0">
                        <el-progress stroke-width="10" :show-text="false"
                          :percentage="(scope.row.unlockNum / scope.row.totalNum) * 100 - 0" color="#0D5570"></el-progress>
                        <div class="progressBox">({{ scope.row.unlockNum }}/{{ scope.row.totalNum }})</div>
                      </div>
                      <div v-else>
                        <span>学校专区实验</span>
                      </div>
                      
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div v-else>
              <div class="recover-button">
                <h3>{{ courseName }}</h3>
                <br />
                <el-button class="themeButton" :disabled="!is_create"
                  @click="handleOpen('recover', '恢复项目')">恢复</el-button>
              </div>
            </div>
          </el-tab-pane>
          <!-- add buy wyf 2023-07-28 -->
          <!-- 慕课课程 -->
          <el-tab-pane label="慕课课程" name="fifth" class="mooc">
            <div class="expSearch">
              <el-button class="themeButton addnewCourseButton" @click="updateOpenCourseInfo(0)">保存</el-button>
              <el-button v-if="status === '0'" class="themeButton addnewCourseButton" @click="applyOnline">申请上线</el-button>
              <el-button v-if="status === '1'" class="themeButton addnewCourseButton">申请中</el-button>
              <el-button v-if="status === '2'" class="themeButton addnewCourseButton">已拒绝</el-button>
              <el-button v-if="status === '3'" class="themeButton addnewCourseButton">已上线</el-button>
            </div>
            <!-- 表单 -->
            <el-form ref="form" label-position="left" label-width="150px">
              <el-form-item label="慕课名称：">
                <el-input style="width: 60%;" placeholder="请输入慕课名称" v-model="moocName"></el-input>
              </el-form-item>
              <el-form-item label="所属学校：" class="borderNone">
                <el-input :disabled ="true" style="width: 60%;" placeholder="请输入所属学校" v-model="affiliation"></el-input>
              </el-form-item>
              <el-form-item label="教师姓名：">
                <el-input style="width: 60%;" v-model="teacherName" placeholder="( 教师姓名 )"></el-input>
              </el-form-item>
              <el-form-item label="绑定班级">
                <!-- <el-input style="width: 60%;" v-model="teacherName" placeholder="( 教师姓名 )"></el-input> -->
                <el-select v-model="open_group_id" placeholder="请选择绑定班级！" style="width: 60%;">
                  <el-option
                    v-for="item in openGroupList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="请选择：">
                <el-radio-group v-model="level">
                  <el-radio :label="0">默认</el-radio>
                  <el-radio :label="1">国一流</el-radio>
                  <el-radio :label="2">省一流</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="慕课介绍：">
                <el-input style="width: 60%;" placeholder="请输入慕课介绍" v-model="moocIntroduce"></el-input>
              </el-form-item>
              <el-form-item label="授课目标：">
                <el-input style="width: 60%;" placeholder="请输入授课目标" v-model="teach"></el-input>
              </el-form-item>
              <el-form-item label="课程大纲：">
                <vue-ueditor-wrap class="edit_qusetionDiv" @ready="ready" ref="editor" v-model="msgOutline" :config="config" style="width: 60%;"></vue-ueditor-wrap>
              </el-form-item>
              <el-form-item label="参考资料：">
                <vue-ueditor-wrap class="edit_qusetionDiv" @ready="ready" ref="editor" v-model="msgMaterial" :config="config" style="width: 60%;"></vue-ueditor-wrap>
              </el-form-item>
              <el-form-item label="慕课封面：">
                <el-upload class="avatar-uploader el-upload-padding" :action="uploadOpenImagePicFilePath"
                    :show-file-list="false" :on-success="uploadOpenImageSuccess"
                    :before-upload="beforeAvatarUpload">
                    <img v-if="openImagePic" :src="openImagePic" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <span style="color: #ccc; vertical-align: bottom;">&nbsp; 建议图片比例(2:1)</span>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <el-dialog
        title="共享课程"
        :visible.sync="dialogShareVisible"
        width="30%"
    >
        <span>
            <span style="font-size: 0.8333vw;">请选择要共享的同事：</span><br><br>
            <el-select v-model="selectTogether" multiple placeholder="请选择" style="width:100%;">
                <el-option
                v-for="item in shareTogetherList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                </el-option>
            </el-select>
        </span>
        <span
            slot="footer"
            class="dialog-footer"
            v-if="handleSign != 'is_qrcode'">
            <el-button class="themecancelButton" @click="dialogShareVisible = false">取 消</el-button>
            <el-button class="themeButton" @click="dialogShareFix()">确 定</el-button>
        </span>
    </el-dialog>

    <div class="bindprojectDialog" v-show="dialogProjectVisible">
      <!-- 添加绑定项目 -->
      <el-dialog title="绑定项目" :visible.sync="dialogProjectVisible" width="40%">
        <span>
          <el-select v-model="project_id" multiple placeholder="请选择" class="width100">
            <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button class="themecancelButton" @click="dialogProjectVisible = false">取消</el-button>
          <el-button class="themeButton" @click="projectDialogExec()">绑定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 设置弹窗 -->
    <div class="addcolleagueDialog" v-show="dialogMoveVisible">
      <el-dialog title="移交课程" :visible.sync="dialogMoveVisible" width="50%">
        <span class="export-span">
          <p>将课程相关联的班级、项目以及资源、题库、报告一并转移给其他教师！过程不可逆，是否继续？</p>
          <br>
          <el-select v-model="move_course_user_id" placeholder="请选择课程负责人！">
            <el-option
              v-for="item in togetherList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </span>
        <div slot="footer" class="dialog-footer" >
          <el-button class="themecancelButton" @click="dialogMoveVisible = false">取消</el-button>
          <el-button class="themeButton" @click="transferCourse()">确定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 设置弹窗 -->
    <div class="addcolleagueDialog" v-show="dialogSetVisible">
      <el-dialog :title="dialogSetTitle" :visible.sync="dialogSetVisible" width="30%">
        <!-- 添加同事 -->
        <span class="export-span" v-if="setHandleSign == 'add_together'">
          <!-- 添加同事 -->
          <span class="dialog-span">
            <el-select class="width100" v-model="together_id" filterable remote reserve-keyword placeholder="请输入同事姓名！"
              :remote-method="remoteMethod" :loading="loading">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </span>
        </span>
        <!-- 保存提示移交项目 -->
        <!-- <span v-if="setHandleSign == 'courseUpdate'" class='center'>
            <div><h3>您确定要将课程转移给 {{create_user_name}} 么？</h3></div>
        </span>-->
        <div slot="footer" class="dialog-footer" v-if="handleSign != 'is_qrcode'">
          <el-button class="themecancelButton" @click="dialogSetVisible = false">取消</el-button>
          <el-button :class="together_id ? 'themeButton' : 'darkButton'" @click="dialogSetFix()">添加</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="addnewcorseDialog" v-show="dialogVisible">
      <!-- 添加/修改/删除/恢复项目弹窗 -->
      <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
        <span class="export-span">
          <!-- 重命名 -->
          <span v-if="handleSign == 'create'">
            <el-input v-model="courseName" placeholder="请输入课程名称！"></el-input>
          </span>
          <!-- 隐藏 -->
          <span v-else-if="handleSign == 'hide'" class="dialog-span">
            <h3>您确定要隐藏 "{{ courseName }}" 么？</h3>
          </span>
          <!-- 恢复 -->
          <span v-else-if="handleSign == 'recover'" class="dialog-span">
            <h3>您确定要恢复 "{{ courseName }}" 么？</h3>
          </span>
          <!-- 删除 -->
          <span v-else-if="handleSign == 'delete'" class="dialog-span">
            <h3>您确定要删除 "{{ courseName }}" 么？</h3>
          </span>
        </span>
        <span slot="footer" class="dialog-footer" v-if="handleSign != 'is_qrcode'">
          <el-button class="themecancelButton" @click="dialogVisible = false">取消</el-button>
          <el-button v-if="handleSign == 'create'" class="themeButton" @click="dialogFix()">新建</el-button>
          <el-button v-else-if="handleSign == 'hide'" class="themeButton" @click="dialogFix()">隐藏</el-button>
          <el-button v-else-if="handleSign == 'recover'" class="themeButton" @click="dialogFix()">恢复</el-button>
          <el-button v-else-if="handleSign == 'delete'" class="themeButton" @click="dialogFix()">删除</el-button>
        </span>
      </el-dialog>
    </div>
    <div class="allunlockDialog" v-show="allunlockDialogVisible">
      <!-- 添加/修改/删除/恢复项目弹窗 -->
      <el-dialog title="提示" :visible.sync="allunlockDialogVisible" width="30%">
        <span>
          <div class="unlockText">账户余额：{{ balance }} 积分</div>
          <div class="unlockText">实验 {{ lockNum }} 人购买需：{{ totalMoneySpent }} 积分</div>
          <div class="unlockText">是否购买？</div>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button class="unlockcancelButton" @click="allunlockDialogVisible = false">取消</el-button>
          <el-button class="unnlockaddButton" @click="allUnlock">确定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 设置统计弹窗 -->
    <el-dialog
      title="团队班级列表"
      :visible.sync="statVisiable"
      width="50%" 
      
      class="stat-dialog">
      <div class="export" @click="exportStatExcelFile"><i class="el-icon-top"></i>导出</div>
      <el-table
        :data="statTableList"
        stripe
        height="550"
        style="width: 100%;">
        <el-table-column
          prop="name"
          label="班级（在修）"
          width="500">
        </el-table-column>
        <el-table-column
          prop="teacherName"
          label="负责人">
        </el-table-column>
        <el-table-column
          prop="studentNum"
          label="人数">
        </el-table-column>
        <el-table-column
          prop="studentRegNum"
          label="注册人数">
        </el-table-column>
      </el-table>
      <div class="total">
        <span class="width64">合计</span>
        <span class="width12"></span>
        <span class="width12">{{studentNumTotal}}</span>
        <span class="width12">{{studentRegNumTotal}}</span>
      </div>
    </el-dialog>

    <!-- 点击申请上线时的弹窗 -->
    <el-dialog
      title="提示"
      :visible.sync="applyOnlineVisible"
      width="30%"
      class="applyOnlineDialog"
    >
      <span>提交申请后信息无法修改,是否确认申请上线?</span>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="applyOnlineVisible = false">取 消</el-button>
        <el-button size="mini" style="background-color: #0D5570;" type="primary" @click="updateOpenCourseInfo(1)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="scss" >
@import "@/assets/css/teacher/course/coursenoScoped.scss";

.white {
  background-color: #CCC;
}
</style>
<style lang="scss" scoped>
@import "@/assets/css/teacher/course/course.scss";
</style>

<script>
import projectList from "@/assets/js/teacher/course/course.js";
export default {
  ...projectList
};
</script>











