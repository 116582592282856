import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import { domainUrl, courseSorcePageSize, uploadFilePath , editorUploadUrl , uploadOpenImagePicFilePath } from "@/utils/const";
import { sleep, startLoading, endLoading, encryptCode } from "@/utils/function";
import API from '@/api/teacher/course/course';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
import VueUeditorWrap from 'vue-ueditor-wrap'
export default {
    name: "other",
    components: {
        VueUeditorWrap
    },
    inject: ['reload'],
    data() {
        return {
            sortAbled: false,
            courseClassList: [],
            courseProjectList: [],
            isRecover: true,
            isShow: true,
            class_type: -3,
            class_typeName: -3,
            class_typeList: [
                {
                    id: -3,
                    name: '全部课程'
                },
                {
                    id: -2,
                    name: '我的课程'
                },
                {
                    id: -1,
                    name: '共享课程'
                }
            ],
            is_create: false,
            course_team: [],
            expData: [
                {
                    project_name: '等会撒趁风使柁',
                    exp_name: "符号搜哈佛年飞机票都按时",
                    unlock: '70'
                }
            ],
            // =======================================================
            // 左侧项目列表 数据
            deleteCourseShowSign: false,
            activeName: "first",
            courseList: [],
            historyCourseList: [],
            historyNum: 0,
            togetherList_copy: [],
            currentCourse_id: 0,
            togetherListSearch: [
                { id: "1", name: "Alabama" },
                { id: "2", name: "Alaska" },
            ],
            dialogVisible: false,
            dialogTitle: "",
            courseName: "",
            course_id: "",
            handleSign: "",
            rightContentShow: true,
            // =======================================================
            // 右侧课程数据  设置
            uploadFilePath: process.env.VUE_APP_DOMAIN_URL + uploadFilePath,
            courseInfo: {},
            togetherList: [],
            certList: [],
            certAll: 0,
            getCert: 0,
            dialogSetVisible: false,
            allunlockDialogVisible: false,
            dialogSetTitle: "",
            setHandleSign: "",
            loading: false,
            together_id: "",
            options: [],
            create_uid: "",
            create_user_name: "",
            // =======================================================
            // 右侧课程数据  项目
            projectTotalHours: 0,
            projectTotalWeight: 0,
            projectHasList: [],
            dialogProjectVisible: false,
            projectList: [],
            project_id: [],
            // =======================================================
            // 右侧课程数据  成绩
            scoreGroupId: "",
            scoreGroupList: [],
            scoreTableTitleList: [],
            scoreTableData: [],
            scorePage: 0,
            scorePageSize: courseSorcePageSize,
            scoreDataTotal: 0,
            courseList2: [],
            groupList: [],
            group_id: '',
            search: '',
            expprojectList: [],
            balance: '',
            lockNum: '',
            totalMoneySpent: '',
            statVisiable:false,
            statTableList:[],
            studentNumTotal:0,
            studentRegNumTotal:0,
            // ------------------------------------------------------------------------
            // 参考资料
            msgMaterial: '',
            msgOutline: '',
            moocName: '',
            affiliation: '',
            teacherName: '',
            moocIntroduce: '',
            level: 0,
            teach: '',
            editor: {},
            config: 
            {
                // 控制添加题目框的显示与隐藏
                addshow:true,
                // 初始容器宽度
                initialFrameWidth: "100%",
                // 初始容器高度
                initialFrameHeight: 100,
                // 你的UEditor资源存放的路径,相对于打包后的index.html
                UEDITOR_HOME_URL: "./Editor/",
                // 编辑器不自动被内容撑高
                // autoHeightEnabled: false,
                // 如果需要上传功能,找后端小伙伴要服务器接口地址
                serverUrl: editorUploadUrl,
                // 关闭自动保存
                enableAutoSave: false,
                // 自定义工具栏，需要额外选项可以参考ueditor.config.js
                toolbars: [
                    [
                        "formatmatch", // 格式刷
                        "removeformat", //清除格式
                        'paragraph', //段落格式
                        "fontfamily", //字体
                        "fontsize", //字号
                        "bold", //加粗
                        "italic", //斜体
                        "underline", //下划线
                        "forecolor", //字体颜色
                        "justifyleft", //居左对齐
                        "justifyright", //居右对齐
                        "justifycenter", //居中对齐
                        "spechars", //特殊字符
                        'touppercase', //字母大写
                        'tolowercase', //字母小写
                        'simpleupload', //单图上传
                        "attachment", //附件
                    ],
                ],
            },
            applyOnlineVisible: false,
            uploadOpenImagePicFilePath: process.env.VUE_APP_DOMAIN_URL + uploadOpenImagePicFilePath,
            openImagePic: '',
            status: '0',
            statusText: '申请上线',
            openGroupList:[],
            open_group_id:undefined,
            dialogMoveVisible:false,
            move_course_user_id:'',

            shareTogetherList:[],
            selectTogether:[],
            id:0,
            dialogShareVisible:false,
        };
    },
    created() 
    {
        const self = this;
        self.course_id = self.$router.currentRoute.query.course_id ? self.$router.currentRoute.query.course_id : '';
        self.search = self.$router.currentRoute.query.search ? self.$router.currentRoute.query.search : '';
        self.activeName = self.$router.currentRoute.query.activeName ?? 'first';
        self.scorePage = self.$router.currentRoute.query.page ?? 0;
        self.class_type = self.$router.currentRoute.query.sign ?? -3;
        self.class_typeList.forEach((item) => {
            if (item.id == self.class_type) {
                self.class_type = item.name
                self.class_typeName = item.id
            }
        })
        self.getCourseList();
    },
    mounted() 
    {
        const self = this;
        self.rowDrop();
        if (self.courseList.length > 0) {
            sleep(800).then(() => {
                self.updateCssStyle(self.courseList[0].id);
            });
        }
        self.list = this.togetherListSearch.map(item => {
            return { value: `value:${item.id}`, label: `${item.name}` };
        });
    },
    methods: 
    {
        dialogShareFix()
        {
            const self = this;
            let datas = {
                together_id: self.selectTogether,
                id: self.id
            };
            console.log(datas);
            console.log(self.selectTogether);
            self.axios
                .post("/index.php/course/shareCourse",datas)
                .then((result)=>{
                self.$Loading.hide();
                nprogressClose();
                if(result.data.code > 0)
                {
                    self.dialogShareVisible = false;
                }
                else
                {
                    self.$Tips({
                    // 消息提示内容
                    message: result.data.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    // 消失时间
                    displayTime: 1500
                    });return;
                }
            })//*/
        },

        handleShare(id,name)
        {
            const self = this;
            self.dialogShareVisible = true;
            self.id = id;
            console.log(id);
            console.log(name);
        },

        transferCourse()
        {
            const self = this;
            let data = {
                create_uid: self.move_course_user_id,
                courseid: self.course_id,
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            // 请求数据
            API.transferCourse(data)
                .then((result)=>{
                    self.getCourseList();
                    self.dialogMoveVisible = false;
                    nprogressClose();
                    self.$Loading.hide();
                })
                .catch(()=>{
                    nprogressClose();
                    self.$Loading.hide();
                })
        },

        moveHandleOpen()
        {
            this.dialogMoveVisible = true;
        },

        exportStatExcelFile()
        {
            let url = "https://app.mools.net/backend/public/index.php/course/exportStatExcelFile?course_id="+this.course_id;
            window.open(url);
        },

        statCourseGroup()
        {
            const self = this;
            self.statVisiable = true;
            let data = {
                course_id: self.course_id,
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            // 请求数据
            API.getCourseStudentGroupList(data)
                    .then((result)=>{
                        self.statTableList = result.list;
                        self.studentNumTotal = result.studentNumTotal;
                        self.studentRegNumTotal = result.studentRegNumTotal;
                        nprogressClose();
                        self.$Loading.hide();
                    })
                    .catch(()=>{
                        nprogressClose();
                        self.$Loading.hide();
                    })
        },

        /**
         * @name: 锁定页面，不允许拖拽
         */
        lockSort() 
        {
            this.sortAbled = !this.sortAbled;
            this.updateCssStyle(this.course_id)
        },

        /**
         * @name: 选择班级类型
         */
        classChangeOption() {},
        /**
         * @name: 分页序号递增
         */
        indexMethod(index) 
        {
            let currentPage = this.scorePage; // 当前页码
            let pageSize = this.scorePageSize; // 每页条数
            return (index + 1) + (currentPage - 1) * pageSize; // 返回表格序号
        },

        /**
        * @name: 展示全部解锁对话框
        */
        showallunlockDialogVisible() 
        {
            if (!this.group_id) {
                return this.$Tips({
                    message: '请选择班级',
                    messageType: 'warning',
                    button: [
                        {
                            type: 1,
                            text: '知道了'
                        }
                    ]
                })
                // Message.info('请选择班级')
            }
            this.allunlockDialogVisible = true
        },

        allUnlock()
        {
            let self = this
            let data = {
                group_id: self.group_id,
                totalMoneySpent: self.totalMoneySpent
            }
            API.unLockTotalExp(data)
                .then((res) => {
                    if (res.code > 0) {
                        // Message.success(res.msg)
                        self.allunlockDialogVisible = false;
                        self.getGroupList();
                    }
                    else {
                        self.$Tips({
                            // 消息提示内容
                            message: res.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            // 延时时间
                            displayTime: 1500
                        })
                    }
                })
        },

        getGroupList() 
        {
            try {
                let self = this
                let data = {
                    course_id: self.course_id
                }
                self.$router.push("/teacher/course?course_id=" +
                    self.course_id +
                    '&activeName=' +
                    self.activeName +
                    '&search=' +
                    self.search +
                    '&sign=' +
                    self.class_typeName)

                if (!self.isShow) {
                    return;
                }
                else {
                    nprogressStart();
                    self.$Loading.show()
                    API.getGroupList(data)
                        .then((res) => {
                            nprogressClose();
                            self.$Loading.hide()
                            if (res.code > 0) {
                                self.groupList = res.underList
                                if (!self.group_id) {
                                    if (res.underList.length == 0) {
                                        return this.$Tips({
                                            message: '该课程暂无班级',
                                            messageType: 'warning',
                                            button: [
                                                {
                                                    type: 1,
                                                    text: '知道了'
                                                }
                                            ]
                                        })
                                    }
                                    else {
                                        self.group_id = res.underList[0].id
                                    }

                                }

                                self.getVirtualDataList()
                            }
                            else {
                                return this.$Tips({
                                    message: res.msg,
                                    messageType: 'warning',
                                    button: [
                                        {
                                            type: 1,
                                            text: '知道了'
                                        }
                                    ]
                                })
                                // Message.info(res.msg)
                            }
                        })
                }

            }
            catch (error) {
                // Message.info('该课程没有班级')
                return this.$Tips({
                    // 消息提示内容
                    message: "该课程没有班级",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    button: [
                        {
                            type: 1,
                            text: '知道了'
                        }
                    ]
                })
            }
        },

        getVirtualDataList() 
        {
            let self = this
            self.$router.push("/teacher/course?course_id=" +
                self.course_id +
                '&activeName=' +
                self.activeName +
                '&search=' +
                self.search +
                '&group_id=' +
                self.group_id +
                '&sign=' +
                self.class_typeName)
            let data = {
                group_id: self.group_id,
                search: self.search
            }
            if (!self.group_id) {
                // return  Message.info('请选择班级')
                return this.$Tips({
                    // 消息提示内容
                    message: "请选择班级",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    button: [
                        {
                            type: 1,
                            text: '知道了'
                        }
                    ]
                })
            }
            nprogressStart();
            self.$Loading.show()
            API.getVirtualDataList(data)
                .then((res) => {
                    nprogressClose();
                    self.$Loading.hide()
                    if (res.code > 0) {
                        self.expprojectList = res.projectList
                        self.balance = res.balance
                        self.lockNum = res.lockNum
                        self.totalMoneySpent = res.totalMoneySpent
                        //    endLoading(startLoading());
                    }
                })
                .catch(() => {
                    nprogressClose();
                    self.$Loading.hide()
                })
        },

        /**
         * @name: 展示全部解锁对话框
         */
        showallunlockDialog() 
        {
            if (!this.group_id) {
                return this.$Tips({
                    // 消息提示内容
                    message: "请选择班级",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    button: [
                        {
                            type: 1,
                            text: '知道了'
                        }
                    ]
                })
                //    return Message.info('请选择班级')
            }
            this.allunlockDialogVisible = true
        },

        /**
         * @name: 项目列表拖拽后重新排序
         */
        compare(property) 
        {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value2 - value1;
            }
        },

        rowDrop() 
        {
            const self = this;
            const tbody = document.querySelector(".sortableTable");
            const sortable = new self.Sortable(tbody,
                {
                    onEnd: (evt) => { // 监听拖动结束事件
                        let data = {};
                        let sortNo = '';
                        let id = '';
                        // evt.oldIndex——当前行的被拖拽前的顺序;
                        // evt.newIndex——当前行的被拖拽后的顺序;
                        if (evt.oldIndex < evt.newIndex) {
                            // 当拖拽完成时不显示loading效果，本地存储loading为false不展示loading效果;
                            window.sessionStorage.setItem('loading', 'false');
                            if (evt.newIndex == self.courseList2.length - 1) {
                                sortNo = self.courseList2[evt.newIndex].sort_no - 1;
                                id = self.courseList2[evt.oldIndex].id;
                                self.courseList2[evt.oldIndex].sort_no = sortNo;
                                // 防止拖拽后从后台获取最新数据页面刷新,影响用户体验,前端处理排序
                                self.courseList2 = self.courseList2.sort(self.compare('sort_no'));
                            }
                            else {
                                let sortbefore = self.courseList2[evt.newIndex].sort_no - 0;
                                let sortnext = self.courseList2[evt.newIndex + 1].sort_no - 0;
                                sortNo = (sortbefore + sortnext) / 2;
                                id = self.courseList2[evt.oldIndex].id;
                                self.courseList2[evt.oldIndex].sort_no = sortNo;
                                self.courseList2 = self.courseList2.sort(self.compare('sort_no'));
                            }
                            data = {
                                id: id,
                                sort_no: sortNo,

                            };
                        }
                        else {
                            if (evt.newIndex == 0) {
                                sortNo = self.courseList2[evt.newIndex].sort_no - 0 + 1;
                                id = self.courseList2[evt.oldIndex].id;
                                self.courseList2[evt.oldIndex].sort_no = sortNo;
                                self.courseList2 = self.courseList2.sort(self.compare('sort_no'));
                            }
                            else {
                                let sortbefore = self.courseList2[evt.newIndex].sort_no - 0;
                                let sortnext = self.courseList2[evt.newIndex - 1].sort_no - 0;
                                sortNo = (sortbefore + sortnext) / 2;
                                id = self.courseList2[evt.oldIndex].id;
                                self.courseList2[evt.oldIndex].sort_no = sortNo;
                                self.courseList2 = self.courseList2.sort(self.compare('sort_no'));
                            }
                            // 当拖拽完成时不显示loading效果，本地存储loading为false不展示loading效果
                            window.sessionStorage.setItem('loading', 'false');
                            data = {
                                id: id,
                                sort_no: sortNo,
                            };
                        };
                        API.updateCourseSortNo(data)
                            .then((result) => {
                                if (result.code > 0) {
                                    window.sessionStorage.setItem('loading', 'true')
                                }
                                self.getCourseProjectList();
                            })
                    }
                });
        },

        jumpToProject(project_id) {
            var self = this;
            // var url = "/lims/web/ManagerSystem/dist/#/teacher/projectList";
            // window.open(url);
            window.open("#/teacher/projectList?project_id=" + project_id + '&activeName=' + 'first' + '&sign=' + '-1')
            // self.$router.push({
            //     path: "/teacher/projectList",
            //     query: {
            //       project_id: project_id,
            //       activeName: 'first',
            //       sign: -1
            //     },
            //   }).then(()=>{
            //     self.reload();
            //   });
        },

        jumpToGroup(group_id) {
            var self = this;
            // var url = "/lims/web/ManagerSystem/dist/#/teacher/projectList";
            // window.open(url);
            window.open("#/teacher/groupList?group_id=" + group_id + '&activeName=' + 'first')
            // self.$router.push({
            //     path: "/teacher/groupList",
            //     query: {
            //         group_id: group_id,
            //         activeName: 'first',
            //     },
            //   }).then(()=>{
            //     self.reload();
            //   });
        },

        handleScorePageChange(val)
        {
            const self = this;
            self.scorePage = val;
            self.$router.push("/teacher/course?course_id=" +
                self.course_id +
                '&activeName=' +
                self.activeName +
                '&search=' +
                self.search +
                '&group_id=' +
                self.group_id +
                '&page=' +
                self.scorePage +
                '&sign=' +
                self.class_typeName)
            self.getCourseScoreData();
        },

        scoreSelectChange() {
            const self = this;
            // console.log(self.scoreGroupId);
            self.getCourseScoreData();
        },

        getCourseScoreData() 
        {
            const self = this;
            // self.scoreGroupId = "";
            self.scoreTableData = [];
            let data = {
                courseid: self.course_id,
                grpid: self.scoreGroupId,
                page: self.scorePage,
                pagesize: self.scorePageSize,
            };
            self.$router.push("/teacher/course?course_id=" +
                self.course_id +
                '&activeName=' +
                self.activeName +
                '&search=' +
                self.search +
                '&sign=' +
                self.class_typeName)
            if (!self.isShow) {
                return;
            }
            else {
                nprogressStart();
                self.$Loading.show()
                API.getCourseScoreData(data)
                    .then((result) => {
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide()
                        if (result.code > 0) {
                            self.scoreGroupList = result.groupIdArray;
                            self.scoreTableTitleList = result.proarr;
                            self.scoreTableData = result.releasearr;
                            self.scoreGroupId = result.grpid;
                            self.scoreDataTotal = result.total;
                            if (isNaN(parseInt(result.grpid))) {
                                self.scoreGroupId = '';
                            }
                            else {
                                self.scoreGroupId = parseInt(result.grpid);
                            }
                        }
                        else {
                            self.scoreGroupList = [];
                            self.scoreTableData = [];
                            self.scoreTableTitleList = [];
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                button: [
                                    {
                                        type: 1,
                                        text: '知道了'
                                    }
                                ]
                            })
                        }
                    })
                    .catch(() => {
                        nprogressClose();
                        self.$Loading.hide()
                    })
            }
            // 开启loading

        },

        /**
         * @name: 课程项目列表分组换色显示
         */
        projectTableRowClassName(row) 
        {
            if (row.row.class == "brown") {
                return "brown";
            }
            return "";
        },

        /**
         * @name: 权重配置
         */
        weightUpdate(obj) 
        {
            const self = this;
            let data = {
                id: obj.id,
                proportion: (obj.proportion - 0).toFixed(1)
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show()
            API.updateCourseWeight(data)
                .then((result) => {
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide()
                    if (result.code > 0) {
                        // Message.success(result.msg);
                        self.getCourseProjectList();
                    }
                })
                .catch(() => {
                    nprogressClose();
                    self.$Loading.hide()
                })
        },

        sortNoUpdate(obj) 
        {
            console.log(obj)
            const self = this;
            let data = {
                id: obj.id,
                project_grp: obj.project_grp,
            };
            API.updateCourseGroup(data)
                .then((result) => {
                    if (result.code > 0) {
                        // Message.success(result.data.msg);
                    }
                    self.getCourseProjectList();
                })
        },

        projectDialogExec() 
        {
            const self = this;
            let data = {
                courseid: self.course_id,
                project_id: self.project_id
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show()
            API.updateCourseProject(data)
                .then((result) => {
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide()
                    if (result.code > 0) {
                        // Message.success(result.msg);
                        self.dialogProjectVisible = false;
                        self.project_id = [];
                        self.getCourseProjectList();
                    }
                })
                .catch(() => {
                    nprogressClose();
                    self.$Loading.hide()
                })
        },
        /**
         * @name: 获取可绑定项目列表
         */
        getProjectList() 
        {
            const self = this;
            let data = {
                courseid: self.course_id
            };
            API.getProjectList(data)
                .then((result) => {
                    if (result.code > 0) {
                        self.projectList = result.data;
                    }
                })
        },

        /**
         * @name: 打开添加绑定项目弹窗
         */
        projectDialogOpen() 
        {
            this.dialogProjectVisible = true;
            this.getProjectList();
        },

        getCourseProjectList() 
        {
            const self = this;
            let data = {
                courseid: self.course_id
            };
            self.$router.push("/teacher/course?course_id=" +
                self.course_id +
                '&activeName=' +
                self.activeName +
                '&search=' +
                self.search +
                '&sign=' +
                self.class_typeName)
            if (!self.isShow) {
                return;
            }
            else {
                // 开启loading
                nprogressStart();
                self.$Loading.show()
                API.getCourseProjectList(data)
                    .then((result) => {
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                        if (result.code > 0) {
                            self.is_create = result.is_create
                            result.data?.forEach((item) => {
                                item.proportion = (item.proportion - 0).toFixed(1)
                            })
                            self.projectHasList = [];
                            self.project_id = [];
                            self.projectHasList = result.data;
                            self.projectTotalHours = result.totalHours;
                            self.projectTotalWeight = result.totalWeight;
                            for (var i = 0; i < self.projectHasList.length; i++) {
                                self.project_id.push(self.projectHasList[i].project_id);
                            }
                        }
                    })
                    .catch(() => {
                        nprogressClose();
                        self.$Loading.hide()
                    })
            }

        },

        updateCourseSet(sign = "") 
        {
            const self = this;
            // if (!sign) {
            //     if (self.courseInfo.create_uid != self.create_uid) {
            //         for (var i = 0; i < self.togetherList.length; i++) {
            //             if (self.create_uid == self.togetherList[i].id) {
            //                 self.create_user_name = self.togetherList[i].name;
            //             }
            //         }
            //         self.dialogSetVisible = true;
            //         self.setHandleSign = "courseUpdate";
            //         self.dialogSetTitle = "课程转移";
            //         return;
            //     }
            // }
            // 关闭弹窗
            self.dialogSetVisible = false;
            self.courseInfo.create_uid = self.create_uid;
            // ===============================================================================================
            // ADD BY LXY  增加课程团队
            self.togetherList.forEach((item) => {
                self.course_team.forEach((key, index) => {
                    if (item.name == key) {
                        self.course_team[index] = item.id
                    }
                })
            })
            let index = self.course_team.findIndex((item) => {
                return item == self.togetherList[0].id
            })
            self.courseInfo.course_team = self.course_team
            // ===============================================================================================
            // 开启loading
            nprogressStart();
            self.$Loading.show()
            // 请求数据
            let data = {
                courseInfo: self.courseInfo,
                id: self.course_id
            }
            API.updateCourseSet(data)
                .then((result) => {
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide()
                    if (result.code > 0) {
                        self.getCourseInfo(self.course_id);
                        for (const item of self.courseList) {
                            if (item.id == self.course_id) {
                                item.name = self.courseInfo.name;
                            }
                        }
                    }
                })
                .catch(() => {
                    nprogressClose();
                    self.$Loading.hide()
                })
        },

        getTogetherList() 
        {
            const self = this;
            API.getTogetherListEsec()
                .then((result) => {
                    if (result.code > 0) {
                        self.togetherList = result.data;
                    }
                })
        },
        /**
         * @name: 设置tab页弹窗点击确定
         */
        dialogSetFix() 
        {
            const self = this;
            if (self.setHandleSign == "add_together") {
                if (!self.together_id) {
                    return self.$Tips({
                        message: '暂未选择同事',
                        messageType: 'warning',
                        displayTime: 1500
                    })
                }
                // 开启loading
                nprogressStart();
                self.$Loading.show()
                //添加同事
                let data = {
                    recip_uid: self.together_id
                };
                API.addTogether(data)
                    .then((result) => {
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide()
                        if (result.code > 0) {
                            self.getTogetherList();
                            // Message.success(result.msg);
                            self.dialogSetVisible = false;
                        }
                        else {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                                button: [
                                    {
                                        type: 1,
                                        text: '知道了'
                                    }
                                ],
                            })
                        }

                    })
                    .catch(() => {
                        nprogressClose();
                        self.$Loading.hide()
                    })
            }
            else if (self.setHandleSign == "courseUpdate") {
                self.updateCourseSet(self.setHandleSign);
            }
        },

        setHandleOpen(sign, title) 
        {
            const self = this;
            self.setHandleSign = sign;
            self.dialogSetTitle = title;
            self.dialogSetVisible = true;
            self.together_id = ''
        },

        /**
         * @name: 添加同事远程搜索
         */
        remoteMethod(query) 
        {
            const self = this;
            if (query === "") {
                this.options = [];
            }
            let data = {
                name: query
            };
            self.loading = true;
            API.searchTogether(data)
                .then((result) => {
                    self.loading = false;
                    if (result.code > 0) {
                        self.list = result.data;
                        self.options = self.list.map(item => {
                            return {
                                value: `${item.id}`,
                                label: `${item.name + item.personal_id}`
                            };
                        });
                    }

                })
        },

        handleAvatarSuccess(res) 
        {
            const self = this;
            if (res.code > 0) {
                self.courseInfo.pic = res.data;
            } 
        },

        beforeAvatarUpload(file) 
        {
            const isJPG =
                file.type === "image/jpeg" ||
                "image/png" ||
                "image/jpg" ||
                "image/gif" ||
                "image/bmp";
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isJPG) {
                // Message.success("上传头像图片只能是 JPG/png/gif/bmp 格式!");
                this.$Tips({
                    // 消息提示内容
                    message: "上传头像图片只能是 JPG/png/gif/bmp 格式",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    button: [
                        {
                            type: 1,
                            text: '知道了'
                        }
                    ]
                })
            }
            if (!isLt2M) {
                // Message.error("上传头像图片大小不能超过 5MB!");
                this.$Tips({
                    // 消息提示内容
                    message: "上传头像图片大小不能超过 5MB",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    button: [
                        {
                            type: 1,
                            text: '知道了'
                        }
                    ]
                })
            }
            return isJPG && isLt2M;
        },

        getcurrentActive(id = "", name = "", sign = "") 
        {
            let self = this;
            self.expprojectList = [];
            self.isShow = true;
            self.scoreGroupId = '';
            self.group_id = '';
            self.rightContentShow = true;
            // 是否重新获取数据
            self.isRecover = false;
            self.course_id = id;
            sleep(1000).then(() => {
                self.updateCssStyle(self.course_id);
            });
            if (sign) {
                self.courseName = name;
                self.rightContentShow = false;
            }
            if (self.activeName == "first") {
                self.getCourseInfo(self.course_id);
            }
            else if (self.activeName == "second") {
                self.getCourseProjectList(self.course_id);
            }
            else if (self.activeName == "third") {
                self.getCourseScoreData();
            }
            else if (self.activeName == "fourth") {
                self.getGroupList()
                // self.getVirtualDataList();
            }
            else if(self.activeName == 'fifth') 
            {
                self.getOpenCourseInfo()
            }
        },
        /**
         * @name: 展示恢复课程
         */
        hideCourse(id = "", name = "", sign = "", isShow = true) 
        {
            let self = this;
            // 是否重新获取数据
            self.isRecover = true;
            self.rightContentShow = false;
            self.isShow = false;
            self.scoreGroupId = ''
            self.course_team = []
            self.activeName = 'first'
            sleep(1000).then(() => {
                self.updateCssStyle(self.course_id);
            });
            self.group_id = ''
            self.search = ''
            self.expprojectList = []
            self.course_id = id;

            self.$router.push("/teacher/course?course_id=" +
                self.course_id +
                '&activeName=' +
                self.activeName +
                '&search=' +
                self.search +
                '&sign=' +
                self.class_typeName)
            if (sign) {
                self.courseName = name;
                self.rightContentShow = false;
            }
            return;
        },
        /**
         * @name: 获取课程信息
         */
        getCourseInfo(id = "", name = "", sign = "", isShow = true) 
        {
            const self = this;
            if (!isShow) {
                self.isShow = false;
                self.scoreGroupId = ''
                self.course_team = []
                self.activeName = 'first'
                sleep(1000).then(() => {
                    self.updateCssStyle(self.course_id);
                });
                self.group_id = ''
                self.search = ''
                self.expprojectList = []
                self.course_id = id;
                self.rightContentShow = false;
                self.$router.push("/teacher/course?course_id=" +
                    self.course_id +
                    '&activeName=' +
                    self.activeName +
                    '&search=' +
                    self.search +
                    '&sign=' +
                    self.class_typeName)
                if (sign) {
                    self.courseName = name;
                    self.rightContentShow = false;
                }
                return;
            }
            else {
                self.scoreGroupId = ''
                self.course_team = []
                self.activeName = 'first'
                sleep(1000).then(() => {
                    self.updateCssStyle(self.course_id);
                });
                self.group_id = ''
                self.search = ''
                self.expprojectList = []
                self.course_id = id;
                self.rightContentShow = true;
                self.$router.push("/teacher/course?course_id=" +
                    self.course_id +
                    '&activeName=' +
                    self.activeName +
                    '&search=' +
                    self.search +
                    '&sign=' +
                    self.class_typeName)
                if (sign) {
                    self.courseName = name;
                    self.rightContentShow = false;
                }
                let data = {
                    course_id: self.course_id
                };
                // 开启loading
                nprogressStart();
                self.$Loading.show()
                API.getCourseInfo(data)
                    .then((result) => {
                        if (result.code > 0) {
                            let togetherList_copy = JSON.parse(JSON.stringify(result.togetherList))
                            self.togetherList_copy = togetherList_copy;
                            self.is_create = result.is_create;
                            self.courseInfo = result.courseInfo;
                            // let index = result.togetherList.findIndex((item) => {
                            //     return item.name == "我"
                            // })
                            // result.togetherList.splice(index, 1)
                            self.togetherList = result.togetherList;
                            self.certList = result.certList;
                            self.certAll = result.certAll;
                            self.getCert = result.getCert;
                            self.create_uid = result.courseInfo.create_uid;
                            // 课程绑定班级列表
                            self.courseClassList = result.courseClassList;
                            // 课程绑定项目列表
                            self.courseProjectList = result.courseProjectList;
                            // ===============================================================================================
                            // ADD BY LXY  增加课程团队
                            let course_team = [];
                            self.togetherList.forEach((item) => {
                                result.courseInfo.course_team.split(',').forEach((key) => {
                                    if (item.id == key) {
                                        course_team.push(item.id)
                                    }
                                })

                            })
                            self.course_team = course_team
                            // ===============================================================================================
                            // self.activeName = "first";
                        }
                        // 关闭loading
                        // endLoading(startLoading());
                        nprogressClose();
                        self.$Loading.hide()
                    })
                    .catch(() => {
                        nprogressClose();
                        self.$Loading.hide()
                    })
            }
        },

        dialogFix() 
        {
            const self = this;
            let data = {
                courseName: self.courseName,
                course_id: self.course_id,
                sign: self.handleSign
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show()
            // 隐藏
            if (self.handleSign == "hide") {
                API.hideCourse(data)
                    .then((result) => {
                        if (result.code > 0) {
                            self.getCourseList();
                            self.dialogVisible = false;
                            // Message.success(result.data.msg);
                        }
                        else {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                // 延时时间
                                displayTime: 1500
                            })
                        }
                        // 关闭loading
                        // endLoading(startLoading());
                        nprogressClose();
                        self.$Loading.hide()
                    })
                    .catch(() => {
                        nprogressClose();
                        self.$Loading.hide()
                    })
            }
            else if (self.handleSign == "delete") {
                // 删除
                API.deleteCourse(data)
                    .then((result) => {
                        if (result.code > 0) {
                            self.getCourseList();
                            self.dialogVisible = false;
                            // Message.success(result.msg);
                            this.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'success',
                                displayTime: 1500
                            })
                        }
                        else {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                // 延时时间
                                displayTime: 1500
                            })
                        }
                        // 关闭loading
                        // endLoading(startLoading());
                        nprogressClose();
                        self.$Loading.hide();
                    })
                    .catch(() => {
                        nprogressClose();
                        self.$Loading.hide();
                    })
            }
            else if (self.handleSign == "recover") {
                // 恢复
                API.recoverCourse(data)
                    .then((result) => {
                        if (result.code > 0) {
                            self.getCourseList();
                            self.dialogVisible = false;
                            self.rightContentShow = true;
                            // Message.success(result.msg);
                            this.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'success',
                                displayTime: 1500
                            })
                        }
                        else {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                // 延时时间
                                displayTime: 1500
                            })
                        }
                        // 关闭loading
                        // endLoading(startLoading());
                        nprogressClose();
                        self.$Loading.hide()
                    })
                    .catch(() => {
                        nprogressClose();
                        self.$Loading.hide()
                    })
            }
            else if (self.handleSign == "create") {
                // 创建
                let data = {
                    courseName: self.courseName
                };
                API.createCourse(data)
                    .then((result) => {
                        if (result.code > 0) {
                            self.getCourseList();
                            self.dialogVisible = false;
                            self.courseName = '';
                            // Message.success(result.msg);
                            this.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'success',
                                displayTime: 1500
                            })
                        }
                        else {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                // 延时时间
                                displayTime: 1500
                            })
                        }
                        // 关闭loading
                        // endLoading(startLoading());
                        nprogressClose();
                        self.$Loading.hide()
                    })
                    .catch(() => {
                        nprogressClose();
                        self.$Loading.hide()
                    })
            }
        },

        historyCourseShow() {
            const self = this;
            self.deleteCourseShowSign = !self.deleteCourseShowSign;
        },

        getCourseList(e) 
        {
            const self = this;
            if (e) {
                self.class_typeName = e
            }

            let data = {
                sign: typeof (self.class_type == 'string') ? self.class_typeName : self.class_type
            };
            // console.log(self.class_typeName);
            API.getCourseList(data)
                .then((result) => {
                    self.courseList = result.courseList;
                    if (result.code > 0) {
                        self.courseList = result.courseList;
                        self.shareTogetherList = result.togetherList;
                        // 深拷贝课程列表数据
                        self.courseList2 = JSON.parse(JSON.stringify(self.courseList));
                        self.historyCourseList = result.historyCourseList;
                        self.historyNum = result.historyNum;
                        if (!self.course_id) {
                            self.course_id = result.course_id;
                        }
                        self.tabHandleClick()
                        sleep(1000).then(() => {
                            self.updateCssStyle(self.course_id);
                        });
                    }
                    else {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            // 延时时间
                            displayTime: 1500
                        })
                    }
                    // 关闭loading
                    // endLoading(startLoading());
                    nprogressClose();
                    self.$Loading.hide()
                })
                .catch(() => {
                    nprogressClose();
                    self.$Loading.hide()
                })
        },
        /**
         * @name: 更改css样式
         */
        updateCssStyle(id) {
            // css效果
            var d = document.querySelectorAll(".left-module tr");
            for (var p = d.length; p--;) {
                if (d[p].id != id) {
                    d[p].style.backgroundColor = "#FFFFFF"; /*其他*/
                    d[p].classList.remove("selectTr");

                } else {
                    d[p].style.backgroundColor = "#E5ECF1"; /*点击的*/
                    d[p].classList.add("selectTr");
                }
            }
        },

        tabHandleClick(tab, event) 
        {
            const self = this;
            if (self.activeName == "first") 
            {
                self.getCourseInfo(self.course_id);
            } 
            else if (self.activeName == "second") 
            {
                self.getCourseProjectList(self.course_id);
            } 
            else if (self.activeName == "third") 
            {
                self.getCourseScoreData();
            } 
            else if (self.activeName == "fourth") 
            {
                self.getGroupList()
            }else if(self.activeName == 'fifth') 
            {
                self.getOpenCourseInfo()
            }
        },

        handleOpen(sign = "", title = "", id = "", name = "") {
            var self = this;
            self.handleSign = sign;
            self.dialogTitle = title;
            if (id) {
                self.course_id = id;
            }
            if (name) {
                self.courseName = name;
            }
            self.dialogVisible = true;
        },
        /** 
         * @name: 获取editor示例
         */
        ready(instance) {
            this.editor = instance // 保存vue-ueditor-wrap实例
        },

        applyOnline() {
            // console.log(1);
            // 校验输入框是否为空
            if(this.moocIntroduce === '' || this.moocName === '' || this.teacherName === '' || this.affiliation === '' || this.teach === '' || this.msgMaterial === '' || this.msgOutline === '' || this.openImagePic === '') {
                this.$Tips({
                    message: '申请上线需填写当前页面全部信息',
                    messageType: 'warning',
                    button: [
                        {
                            type: 1,
                            text: '知道了'
                        }
                    ]
                })
            } else {
                this.applyOnlineVisible = true
            }
        },

        getOpenCourseInfo() 
        {
            this.$router.push("/teacher/course?course_id=" +
            this.course_id)
            nprogressStart();
            this.$Loading.show()
            let data = {
                course_id: this.course_id
            }
            try {
                API.getOpenCourseInfo(data)
                .then(res =>{
                    nprogressClose();
                    this.$Loading.hide()
                    // console.log(res);
                    this.openGroupList = res.groupList ?? [];
                    if(res.data) 
                    {
                        this.msgMaterial = res.data.ckzl;
                        this.msgOutline = res.data.kcdg;
                        this.moocName = res.data.name;
                        this.level = Number(res.data.level);
                        this.teacherName = res.data.team;
                        this.moocIntroduce = res.data.kcjs;
                        this.teach = res.data.skmb;
                        this.openImagePic = res.data.pic;
                        this.status = res.data.status;
                        this.open_group_id = res.data.group_id;
                    }
                    else 
                    {
                        this.msgMaterial = '';
                        this.msgOutline = '';
                        this.moocName = '';
                        this.level = 0;
                        this.teacherName = '';
                        this.moocIntroduce = '';
                        this.teach = '';
                        this.openImagePic = '';
                        this.status = '0';
                        this.open_group_id = undefined;
                    }
                    this.affiliation = res.schoolName;
                }) 
            } catch (error) {
                nprogressClose();
                this.$Loading.hide()
            }

        },

        /**
         * @name: 上传开放课程封面图片成功
         */
        uploadOpenImageSuccess(res) 
        {
            if (res.code > 0) 
            {
                this.openImagePic = res.data;
            } 
        },

        /**
         * @name: 修改开放课程信息
         * @params: status: 0--默认 1--申请上线 2--拒绝 3--已上线 
         */
        updateOpenCourseInfo(status) 
        {
            let data = {
                courseid: this.course_id,
                pic: this.openImagePic,
                team: this.teacherName,
                kcjs: this.moocIntroduce,
                kcdg: this.msgOutline,
                skmb: this.teach,
                ckzl: this.msgMaterial,
                level: this.level,
                name: this.moocName,
                status: status,
                group_id:this.open_group_id
            }
            // console.log(data);return;
            nprogressStart();
            this.$Loading.show()
            try {

                API.updateOpenCourseInfo(data)
                .then(result => {
                    // console.log(res)
                    nprogressClose();
                    this.$Loading.hide()
                    if (result.code > 0 ) 
                    {
                        this.getOpenCourseInfo()
                    }
                    else
                    {
                        this.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            // 延时时间
                            displayTime: 1500
                        })
                    }
                })
            } 
            catch (error) {
                nprogressClose();
                this.$Loading.hide()
            }
            this.applyOnlineVisible = false;
        }
    }
}
